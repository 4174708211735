export const environment = {
  isProduction: false,
  enableAngularProdMode: true,
  name: 'dev',
  apiHost: 'http://localhost:8008',
  sentryDsn: 'https://3d2ed2a0b18242dda89e57b010b9f456@sentry.lefty.io//6',
  posthogToken: 'phc_XtmNoGyx59aDHz6dCBaU9AcfkWKunBEKL15TsrABGGF',
  posthogHost: 'https://watch.lefty.io',
  firebase: {
    apiKey: 'AIzaSyDMPv47eH1hCcaMd8nnzonNSs1wcGMsqP8',
    authDomain: 'leftyv2-1136.firebaseapp.com',
    databaseURL: 'https://leftyv2-1136.firebaseio.com',
    projectId: 'leftyv2-1136',
    storageBucket: 'leftyv2-1136.appspot.com',
    messagingSenderId: '631454761349',
    appId: '1:631454761349:web:96974fd72a9c04b61e44ec',
  },
};
